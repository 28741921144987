import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import ViewHome from "@/views/Home.vue";
import ViewSetup from "@/views/Setup.vue";
import ViewTournament from "@/views/Tournament.vue";
import ViewImages from "@/views/Images.vue";
import ViewHistory from "@/views/History.vue";
import ViewRanking from "@/views/Ranking.vue";
import ViewProfile from "@/views/Profile.vue";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: ViewHome,
  },
  {
    path: "/profile",
    name: "profile",
    component: ViewProfile,
  },
  {
    path: "/images",
    name: "images",
    component: ViewImages,
  },
  {
    path: "/history",
    name: "history",
    component: ViewHistory,
  },
  {
    path: "/ranking",
    name: "ranking",
    component: ViewRanking,
  },
  {
    path: "/setup",
    name: "setup",
    component: ViewSetup,
  },
  {
    path: "/tournament",
    name: "tournament",
    component: ViewTournament,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
